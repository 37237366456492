// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---themes-gatsby-theme-international-src-templates-legal-legal-tsx": () => import("./../../../themes/gatsby-theme-international/src/templates/Legal/Legal.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-templates-legal-legal-tsx" */),
  "component---themes-gatsby-theme-international-src-pages-index-tsx": () => import("./../../../themes/gatsby-theme-international/src/pages/index.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-pages-index-tsx" */),
  "component---themes-gatsby-theme-international-src-pages-about-us-tsx": () => import("./../../../themes/gatsby-theme-international/src/pages/about-us.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-pages-about-us-tsx" */),
  "component---themes-gatsby-theme-international-src-pages-contact-us-tsx": () => import("./../../../themes/gatsby-theme-international/src/pages/contact-us.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-pages-contact-us-tsx" */),
  "component---themes-gatsby-theme-international-src-pages-menu-tsx": () => import("./../../../themes/gatsby-theme-international/src/pages/menu.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-pages-menu-tsx" */),
  "component---themes-gatsby-theme-international-src-pages-404-tsx": () => import("./../../../themes/gatsby-theme-international/src/pages/404.tsx" /* webpackChunkName: "component---themes-gatsby-theme-international-src-pages-404-tsx" */)
}

